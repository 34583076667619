@font-face {
  font-family: 'Jost*';
  src: local('Jost* Semi'), local('Jost-Semi'), url('../fonts/Jost/Jost-semi.woff2') format('woff2'), url('../fonts/Jost/Jost-semi.woff') format('woff'), url('../fonts/Jost/Jost-semi.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Jost*';
  src: local('Jost* Medium'), local('Jost-Medium'), url('../fonts/Jost/Jost-medium.woff2') format('woff2'), url('../fonts/Jost/Jost-medium.woff') format('woff'), url('../fonts/Jost/Jost-medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Jost*';
  src: local('Jost* Bold'), local('Jost-Bold'), url('../fonts/Jost/Jost-bold.woff2') format('woff2'), url('../fonts/Jost/Jost-bold.woff') format('woff'), url('../fonts/Jost/Jost-bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Jost*';
  src: local('Jost* Book'), local('Jost-Book'), url('../fonts/Jost/Jost-book.woff2') format('woff2'), url('../fonts/Jost/Jost-book.woff') format('woff'), url('../fonts/Jost/Jost-book.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Jost*';
  src: local('Jost* Black'), local('Jost-Black'), url('../fonts/Jost/Jost-black.woff2') format('woff2'), url('../fonts/Jost/Jost-black.woff') format('woff'), url('../fonts/Jost/Jost-black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Jost*';
  src: local('Jost* Light'), local('Jost-Light'), url('../fonts/Jost/Jost-light.woff2') format('woff2'), url('../fonts/Jost/Jost-light.woff') format('woff'), url('../fonts/Jost/Jost-light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
